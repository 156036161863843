import TestimonySlider from "../../sliders/TestimonySlider"

const CareerTestimony = () => {
  return (
    <section className="slider" style={{ backgroundColor: "#F8F8F8" }}>
      <div className="container">
        <h2 className="career-testi-title">
          What They Say About Working in Pharma Metric Labs?
        </h2>
        <TestimonySlider />
      </div>
    </section>
  )
}

export default CareerTestimony
