import { ArrowRightCircle } from "react-feather"
import { AiOutlineBars } from "react-icons/ai"
import Logo from "../../../images/logoPML.png"
import img1 from "../../../upload/NavBabe.png"
import img2 from "../../../upload/NavCA.png"
import img3 from "../../../upload/NavCT.png"
import img4 from "../../../upload/NavOT.png"

const NavbarSub = () => {
  return (
    <section
      className="elementor-element elementor-element-6242961 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section"
      data-id={6242961}
      data-element_type="section"
      data-settings='{"background_background":"classic"}'
    >
      <div className="elementor-container elementor-column-gap-no">
        <div className="elementor-row">
          <div
            className="elementor-element elementor-element-3230b93 elementor-column elementor-col-50 elementor-top-column"
            data-id="3230b93"
            data-element_type="column"
            data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-ac7463f elementor-widget elementor-widget-image"
                  data-id="ac7463f"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="image.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-image">
                      <a data-elementor-open-lightbox href="/">
                        <img
                          width={228}
                          height={54}
                          style={{ width: "200px" }}
                          src={Logo}
                          className="attachment-full size-full"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="elementor-element elementor-element-2a5d3b6 elementor-column elementor-col-50 elementor-top-column"
            data-id="2a5d3b6"
            data-element_type="column"
            data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
          >
            <div className="elementor-column-wrap elementor-element-populated">
              <div className="elementor-widget-wrap">
                <div
                  className="elementor-element elementor-element-11ced52 elementor-widget__width-auto elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-avante-navigation-menu"
                  data-id="11ced52"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="avante-navigation-menu.default"
                >
                  <div className="elementor-widget-container">
                    <div className="themegoods-navigation-wrapper menu_style1">
                      <div className="menu-main-menu-container">
                        <ul id="nav_menu36" className="nav">
                          <li className="menu-item menu-item-has-children current-menu-item ">
                            <a href="/">Home</a>
                          </li>

                          <li className=" menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children arrow">
                            <a href="/AboutUs">About Us</a>
                            <ul class="sub-menu">
                              <li class="menu-item">
                                <a href="/AboutUs">About Us</a>
                              </li>
                              <li class="menu-item">
                                <a href="/AboutUs/OurExpertsAndTeam">
                                  Our Experts and Team
                                </a>
                              </li>
                              <li class="menu-item">
                                <a href="/AboutUs/OurClient">Our Clients</a>
                              </li>
                              <li class="menu-item">
                                <a
                                  href="https://drive.google.com/file/d/1_cQMkFsZJEs4WZbYv6TTrv9bIr9IneH1/view?usp=sharing"
                                  target="_blank"
                                >
                                  Company Profile
                                </a>
                              </li>
                              <li class="menu-item">
                                <a
                                  href="https://drive.google.com/drive/folders/1NOhz_UjQVQ_e7OZ24XVlCGHLbcVXZ62w?usp=sharing"
                                  target="_blank"
                                >
                                  Catalogue
                                </a>
                              </li>
                              <li class="menu-item">
                                <a href="/AboutUs/Careers">Careers</a>
                              </li>
                            </ul>
                          </li>

                          <li className=" menu-item menu-item-type-custom menu-item-object-custom elementor-megamenu megamenu arrow">
                            <a href="#">Services</a>
                            <ul className="elementor-megamenu-wrapper">
                              <div
                                data-elementor-type="wp-post"
                                data-elementor-id={6189}
                                className="elementor elementor-6189"
                                data-elementor-settings="[]"
                              >
                                <div className="elementor-inner">
                                  <div className="elementor-section-wrap">
                                    <section
                                      className="elementor-element elementor-element-b5db8c5 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-inner-section"
                                      data-id="b5db8c5"
                                      data-element_type="section"
                                      data-settings='{"background_background":"classic"}'
                                    >
                                      <div className="elementor-container elementor-column-gap-default">
                                        <div className="elementor-row">
                                          <div
                                            className="elementor-element elementor-element-405d767 elementor-column elementor-col-25 elementor-inner-column"
                                            data-id="405d767"
                                            data-element_type="column"
                                            data-settings='{"background_background":"classic","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-7ffc031 elementor-widget elementor-widget-image"
                                                  data-id="7ffc031"
                                                  data-element_type="widget"
                                                  data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                                                  data-widget_type="image.default"
                                                >
                                                  <div className="navbar-services">
                                                    <div className="navbar-services-image">
                                                      <div className="navbar-services-img">
                                                        <img
                                                          src={img1}
                                                          alt=""
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="navbar-services-heading">
                                                      <h2 className="elementor-heading-title elementor-size-default">
                                                        Bioavailability / <br />
                                                        Bioequivalence Studies
                                                      </h2>
                                                    </div>
                                                    <div className="navbar-services-text px-3">
                                                      <p>
                                                        More than 300 completed
                                                        studies for Indonesian &
                                                        international sponsors
                                                      </p>
                                                    </div>
                                                    <hr />
                                                    <div className="navbar-services-learn">
                                                      <div className="d-flex justify-content-center align-items-center py-3">
                                                        <ArrowRightCircle
                                                          size={16}
                                                        />
                                                        <a
                                                          href="/Services/BABEStudies"
                                                          className="navbar-services-learn-href ps-1"
                                                        >
                                                          Learn More
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-405d767 elementor-column elementor-col-25 elementor-inner-column"
                                            data-id="405d767"
                                            data-element_type="column"
                                            data-settings='{"background_background":"classic","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-7ffc031 elementor-widget elementor-widget-image"
                                                  data-id="7ffc031"
                                                  data-element_type="widget"
                                                  data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                                                  data-widget_type="image.default"
                                                >
                                                  <div className="navbar-services">
                                                    <div className="navbar-services-image">
                                                      <div className="navbar-services-img">
                                                        <img
                                                          src={img3}
                                                          alt=""
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="navbar-services-heading">
                                                      <h2 className="elementor-heading-title elementor-size-default">
                                                        Clinical Trial <br />
                                                        Services
                                                      </h2>
                                                    </div>
                                                    <div className="navbar-services-text px-3">
                                                      <p>
                                                        In-house trials as well
                                                        as collaboration with
                                                        reputable investigators
                                                        and hospitals
                                                      </p>
                                                    </div>
                                                    <hr />
                                                    <div className="navbar-services-learn">
                                                      <div className="d-flex justify-content-center align-items-center py-3">
                                                        <ArrowRightCircle
                                                          size={16}
                                                        />
                                                        <a
                                                          href="/Services/ClinicalTrials"
                                                          className="navbar-services-learn-href ps-1"
                                                        >
                                                          Learn More
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-405d767 elementor-column elementor-col-25 elementor-inner-column"
                                            data-id="405d767"
                                            data-element_type="column"
                                            data-settings='{"background_background":"classic","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-7ffc031 elementor-widget elementor-widget-image"
                                                  data-id="7ffc031"
                                                  data-element_type="widget"
                                                  data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                                                  data-widget_type="image.default"
                                                >
                                                  <div className="navbar-services">
                                                    <div className="navbar-services-image">
                                                      <div className="navbar-services-img">
                                                        <img
                                                          src={img4}
                                                          alt=""
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="navbar-services-heading">
                                                      <h2 className="elementor-heading-title elementor-size-default">
                                                        Contract Analysis
                                                        <br />
                                                        Services
                                                      </h2>
                                                    </div>
                                                    <div className="navbar-services-text px-3">
                                                      <p>
                                                        We offer various
                                                        analytical testings
                                                        including chemical,
                                                        microbiology, physical,
                                                        and other test
                                                      </p>
                                                    </div>
                                                    <hr />
                                                    <div className="navbar-services-learn">
                                                      <div className="d-flex justify-content-center align-items-center py-3">
                                                        <ArrowRightCircle
                                                          size={16}
                                                        />
                                                        <a
                                                          href="/Services/ContractAnalysis"
                                                          className="navbar-services-learn-href ps-1"
                                                        >
                                                          Learn More
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-405d767 elementor-column elementor-col-25 elementor-inner-column"
                                            data-id="405d767"
                                            data-element_type="column"
                                            data-settings='{"background_background":"classic","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                                          >
                                            <div className="elementor-column-wrap  elementor-element-populated">
                                              <div className="elementor-widget-wrap">
                                                <div
                                                  className="elementor-element elementor-element-7ffc031 elementor-widget elementor-widget-image"
                                                  data-id="7ffc031"
                                                  data-element_type="widget"
                                                  data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                                                  data-widget_type="image.default"
                                                >
                                                  <div className="navbar-services">
                                                    <div className="navbar-services-image">
                                                      <div className="navbar-services-img">
                                                        <img
                                                          src={img2}
                                                          alt=""
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="navbar-services-heading">
                                                      <h2 className="elementor-heading-title elementor-size-default">
                                                        Other <br />
                                                        Services
                                                      </h2>
                                                    </div>
                                                    <div className="navbar-services-text px-3">
                                                      <p>
                                                        Other services we
                                                        provide
                                                      </p>
                                                    </div>
                                                    <hr />
                                                    <div className="navbar-services-learn">
                                                      <div className="d-flex justify-content-center align-items-center py-3">
                                                        <ArrowRightCircle
                                                          size={16}
                                                        />
                                                        <a
                                                          href="/Services/Others"
                                                          className="navbar-services-learn-href ps-1"
                                                        >
                                                          Learn More
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </section>
                                  </div>
                                </div>
                              </div>
                            </ul>
                          </li>
                          <li className="menu-item menu-item-has-children arrow">
                            <a href="#">Experience</a>
                            <ul className="sub-menu">
                              <li className="menu-item">
                                <a href="/Experiences/BABEStudies">
                                  BA/BE Studies
                                </a>
                              </li>
                              <li className="menu-item">
                                <a href="/Experiences/ClinicalTrials">
                                  Clinical Trials
                                </a>
                              </li>
                              <li className="menu-item">
                                <a href="/Experiences/ContractAnalysis">
                                  Contract Analysis
                                </a>
                              </li>
                            </ul>
                          </li>

                          <li className="menu-item menu-item-has-children arrow">
                            <a href="#">Gallery</a>
                            <ul className="sub-menu">
                              <li className="menu-item">
                                <a href="/Gallery">Facilities</a>
                              </li>
                              <li className="menu-item">
                                <a href="/Gallery/Publication">Publication</a>
                              </li>
                              <li className="menu-item">
                                <a
                                  href="https://vr-tour-pharmametriclabs.netlify.app/"
                                  target="_blank"
                                >
                                  VR Gallery
                                </a>
                              </li>
                            </ul>
                          </li>

                          <li className="menu-item menu-item-has-children current-menu-item">
                            <a href="/ContactUs">Contact Us</a>
                          </li>
                          <li class="menu-item menu-item-has-children current-menu-item">
                            <a href="/News">News</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-e5dd2bb elementor-shape-circle elementor-widget__width-auto elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-social-icons"
                  data-id="e5dd2bb"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="social-icons.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-social-icons-wrapper" />
                  </div>
                </div>

                <div
                  className="elementor-element elementor-element-294fdfb elementor-widget__width-auto elementor-widget elementor-widget-avante-search"
                  data-id="294fdfb"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="avante-search.default"
                >
                  <div className="elementor-widget-container">
                    <div className="avante-search-icon">
                      <a data-open="tg_search_294fdfb" href="/SearchBar">
                        <i
                          aria-hidden="true"
                          className="fas fa-search iconSize"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-2586fbd elementor_mobile_nav elementor-widget__width-auto elementor-hidden-desktop elementor-view-default elementor-widget elementor-widget-icon"
                  data-id="2586fbd"
                  data-element_type="widget"
                  data-settings='{"avante_ext_is_scrollme":"false","avante_ext_is_smoove":"false","avante_ext_is_parallax_mouse":"false","avante_ext_is_infinite":"false"}'
                  data-widget_type="icon.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-icon-wrapper">
                      <a
                        className="elementor-icon"
                        href="javascript:;"
                        style={{ color: "#00623d" }}
                      >
                        <AiOutlineBars
                          className="iconSizeKonten"
                          aria-hidden="true"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NavbarSub
