import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import ImgComponent from "../content/imgComponent"

const TestimonySlider = () => {
  const testimonies = [
    {
      id: 1,
      name: "Uluk Suharsi Putra",
      company: "Clinical BABE Manager",
      image: "Testimoni_Uluk.png",
      testimony:
        "In my 10 years working here, PML has taught me how to interact with people and help me to discover my true passion. Great and fascinating adventure!",
    },
    {
      id: 2,
      name: "Hanoum Kayasa Swasti",
      company: "Quality Assurance Supervisor",
      image: "Testimoni_Hanoum.png",
      testimony:
        "Working at PML is full of new knowledge and challenges that make us always want to think ahead and be the best",
    },
    {
      id: 3,
      name: "Karina Widianti",
      company: "Finance Accounting Supervisor",
      image: "Testimoni_Karin.png",
      testimony:
        "Working at PML, as an introvert, I get many things to interact with people, build self-potential, and increase my knowledge",
    },
    {
      id: 4,
      name: "Mas Muhammad Luqman Imam Al-As'ari",
      company: "Key Account Executive",
      image: "Testimoni_Luqman.jpg",
      testimony:
        "I have worked in the BA/BE industry for around 4 years. I am grateful to be able to work at PML. This place has lots of challenges and big sales targets, so it taught me to maintain integrity and trust while working",
    },
    {
      id: 5,
      name: "Rahmat Sabar Hakiki",
      company: "IT Support",
      image: "Testimoni_Rahmat.png",
      testimony:
        "Working at PML is flexible, relaxed, friendly yet still full of challenges",
    },
    {
      id: 6,
      name: "Gloria E. F. Lumbantoruan",
      company: "Quality Control Scientist",
      image: "Testimoni_Gloria.png",
      testimony:
        "Lots of new things to learn in PML as PML provides various training. A fun work environment that makes you feel at home and can work well with the team",
    },
    {
      id: 7,
      name: "Didi Marsudi",
      company: "Key Account Executive",
      image: "Testimoni_Didi.png",
      testimony:
        "It's enjoyable to work at PML. I've to be a fast learner and proactive. PML also provides adequate training for me to tackle the challenges I face while working here",
    },
    {
      id: 8,
      name: "Alfionita Zalsa",
      company: "Data Management Administrator",
      image: "Testimoni_Alfionita.png",
      testimony:
        "Working at PML was my first work experience. I'm happy to join PML because for almost 4 years I've gained a lot of new knowledge and experience from other colleagues",
    },

    {
      id: 9,
      name: "Nabila Mudin S",
      company: "Data Management Officer",
      image: "Testimoni_Nabila.png",
      testimony:
        "For the past 2 years, I have been learning a lot at PML.The family-like office environment makes me comfortable and able to work well",
    },
    {
      id: 10,
      name: "Rabella Mufti Soraya",
      company: "Clinical Research Associate",
      image: "Testimoni_Rabella.png",
      testimony:
        "People are supportive, voices are heard, superior are open-minded and provide the room to grow",
    },
  ]

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <>
      <Slider {...settings} className="slider-content btn-style-1">
        {testimonies.map((content) => (
          <div className="item">
            <div className="item-inner">
              <div className="item-inner-image">
                <ImgComponent
                  pmlImg={content.image}
                  className="rounded-image"
                />
              </div>
              <div className="item-inner-content">
                <p className="content-testimony">{content.testimony}</p>
                <h4 className="content-name">{content.name}</h4>
                <h4 className="content-company">{content.company}</h4>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  )
}

export default TestimonySlider
